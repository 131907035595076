<template>
  <div class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line d-flex justify-content-between pl-1 pr-2 position-absolute w-100">
        <div v-if="isCanExportExcel" class="pt-2 pr-2 fs-16px exportExcel">
            <span @click="exportExcel()" class="cursor-pointer text-success">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-explicit-fill" viewBox="0 0 16 16">
                <path d="M2.5 0A2.5 2.5 0 0 0 0 2.5v11A2.5 2.5 0 0 0 2.5 16h11a2.5 2.5 0 0 0 2.5-2.5v-11A2.5 2.5 0 0 0 13.5 0zm4.326 10.88H10.5V12h-5V4.002h5v1.12H6.826V7.4h3.457v1.073H6.826v2.408Z"/>
              </svg>
            </span>
          <span @click="exportExcel()" class="cursor-pointer v-align-sub"> xport do Excel</span>
        </div>
      </div>

      <div class="text-left filter-line d-flex">
        <span class="font-weight-bold pr-2 fs-17px pl-1">Filtry:</span>
        <span class="bold ml-3 pt-label">od</span>
        <b-form-datepicker
            id="dataFrom-datepicker"
            v-model="dateFrom"
            :start-weekday="1"
            size="sm"
            locale="cs"
            class="ml-2 w-260px"
        />

        <span class="bold ml-3 pt-label">do</span>
        <b-form-datepicker
            id="dataTo-datepicker"
            v-model="dateTo"
            :start-weekday="1"
            size="sm"
            locale="cs"
            class="ml-2 w-260px"
        />

        <span class="pl-5 pt-3px">
          [ výsledky: {{ totalItems }} ]
        </span>
      </div>

      <b-table
          sticky-header="calc(100vh - 14.5rem)"
          bordered
          hover
          responsive
          no-border-collapse
          :items="contracts"
          :fields="headersSignedContracts"
          class="stickyTable"
      >
        <template #head()="{ field }">
          <span v-if="field.num" class="rowNumber">{{ field.num }}</span>
          <span v-html="field.label"></span>
        </template>
        <template #cell(kartaZakazky)="{  }">
          <div class="text-center">
            <b-icon-journal
                font-scale="1.3"
                variant="secondary"
            />
          </div>
        </template>
        <template #cell(poskytnutaSlevaPerc)="{ item }">
          <div class="text-center">{{ calcPoskytnutaSlevaPerc(item) }}</div>
        </template>
        <template #cell(cenaNSECelkem)="{ item }">
          <div class="text-center">{{ calcCenaNSECelkem(item) }}</div>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>
<script>

import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import moment from "moment/moment";
import _ from "lodash";
import Axios from "axios";
import saveAs from "save-as";

const getDateFrom = (monthCount = 6) => moment.utc().subtract(monthCount, 'month').toDate();
const getDateTo = () => moment.utc().toDate();
const getStartDay = (date) => moment.utc(date).startOf('day').toDate();
const getEndDay = (date) => moment.utc(date).endOf('day').toDate();

export default {
    name: 'SignedContracts',
    props: {
      tabName: {type: String, required: true},
    },
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      isLoading: false,
      contracts: [],
      limit: 30,
      offset: 0,
      lastScrollTop: 0,
      totalItems: 0,
      dateFrom: getDateFrom(),
      dateTo: getDateTo(),
      headersSignedContracts: [
        {label: 'Karta zakázky', key: 'kartaZakazky', stickyColumn: true, variant: 'color-1', class: 'pl-1 pr-1'},
        {label: 'Číslo zakázky (název adresáře v zakázkách)', key: 'orderNumber', num: 1, stickyColumn: true, variant: 'color-1', class: 'text-left mw-350px max-w-350px stickyColumn'},
        {label: 'Termín dokončení realizace', key: 'terminDokonceniRealizace', num: 2, variant: 'color-1', class: 'mw-100px'},
        {label: 'Cena celkem vč. DPH', key: 'cenaDilaCelkem', num: 3, variant: 'color-1', class: 'mw-125px', formatter: cur => this.formatMoney(cur)},
        {label: 'Cena za zhotovení díla bez DPH', key: 'cenaDila', num: 4, variant: 'color-1', class: 'mw-125px', formatter: cur => this.formatMoney(cur)},

        {label: 'FVE. Cena před slevou bez DPH', key: 'cenaPredSlevou', num: 5, variant: 'color-2', class: 'mw-125px', formatter: cur => this.formatMoney(cur)},
        {label: 'FVE. Poskytnuta sleva', key: 'poskytnutaSlevaKc', num: 6, variant: 'color-2', class: 'mw-100px', formatter: cur => this.formatMoney(cur)},
        {label: 'FVE. Poskytnuta sleva', key: 'poskytnutaSlevaPerc', num: 7, variant: 'color-2', class: 'mw-100px', formatter: cur => this.formatPercent(cur)},

        {label: 'FVE. Cena po slevě bez DPH', key: 'cenaFVEPoSleve', num: 8, variant: 'color-3', class: 'mw-100px', formatter: cur => this.formatMoney(cur)},
        {label: 'FVE. Cena po slevě vč. DPH', key: 'cenaFVECelkem', num: 9, variant: 'color-3', class: 'mw-125px', formatter: cur => this.formatMoney(cur)},
        {label: 'Dobíjecí stanice. Cena bez DPH', key: 'cenaNSE', num: 10, variant: 'color-3', class: 'mw-125px', formatter: cur => this.formatMoney(cur)},
        {label: 'Dobíjecí stanice. Cena vč. DPH', key: 'cenaNSECelkem', num: 11, variant: 'color-3', class: 'mw-125px', formatter: cur => this.formatMoney(cur)},

        {label: '<b>1.ZFA</b>', key: 'ZFA1perc', num: 12, variant: 'color-4', class: 'mw-80px', formatter: cur => this.formatPercent(cur)},
        {label: '<b>2.ZFA</b>', key: 'ZFA2perc', num: 13, variant: 'color-4', class: 'mw-80px', formatter: cur => this.formatPercent(cur)},
        {label: '<b>3.ZFA</b>', key: 'ZFA3perc', num: 14, variant: 'color-4', class: 'mw-80px', formatter: cur => this.formatPercent(cur)},
        {label: '<b>Ostrá faktura</b>', key: 'ostraFaktura', num: 15, variant: 'color-4', class: 'mw-125px', formatter: cur => this.formatPercent(cur)},

      ],
      filter: {
        name: null,
        searchString: null,
      },
      lists: {
        dph: [],
      },
    }
  },
  computed: {
    isCanExportExcel() {
      return this.$store.getters.isExportDoExcel;
    }
  },
  methods: {
    async getItems(mountCall = false) {
      try {
        if (this.offset > this.contracts.length) return;

        this.isLoading = true;
        const url = `/financniAnalyza/getPodepsaneSmlouvyInfo`;
        const {data: {data}} = await axios.post(url, {
          offset: this.offset,
          limit: this.limit,
          filter: this.filter,
          dateFrom: getStartDay(this.dateFrom),
          dateTo: getEndDay(this.dateTo),
          type: this.tabName,
        });
        if (mountCall || !this.offset) {
          this.contracts = data.data;
        } else {
          this.contracts.push(...data.data);
        }
        this.offset = this.offset > this.contracts.length ? this.offset : this.limit + this.offset;
        this.totalItems = data?.metadata?.total || 0;
        this.$emit('updateTotalItems', this.totalItems);
      } catch (e) {
        console.error('getItems', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      } finally {
        this.isLoading = false;
      }
    },
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(['DPH']) || {};
        this.lists.dph = dataConst.DPH;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    calcPoskytnutaSlevaPerc(item) {
      const {poskytnutaSlevaKc, poskytnutaSlevaPerc, cenaPredSlevou} = item;
      if (!poskytnutaSlevaKc) return '';
      return poskytnutaSlevaPerc ? `${poskytnutaSlevaPerc} %` : `${(poskytnutaSlevaKc*100/cenaPredSlevou).toFixed(2) || 0} %`;
    },
    calcCenaNSECelkem(item) {
      if (_.isNull(item.cenaNSE)) return this.formatMoney(0);

      const cenaNSE = _.get(item, 'cenaNSE', 0);
      const dphNSE = _.get(item, 'dphNSE') || this.lists.dph[0].value;
      return  this.formatMoney(Math.round(+cenaNSE * (+dphNSE / 100 + 1)) || 0);
    },
    async handleScroll() {
      const tableEl = this.$el.querySelector('.table-responsive');
      const currentScrollTop = tableEl.scrollTop;
      if (currentScrollTop > this.lastScrollTop && (currentScrollTop + tableEl.offsetHeight + 5 >= tableEl.scrollHeight) && !this.loading) {
        this.loading = true;
        await this.getInfo(true);
      }
      this.lastScrollTop = currentScrollTop;
    },
    async exportExcel() {
      try {
        const url = `/financniAnalyza/exportDoExcel`;
        const {data} = await Axios.post(url, {
              dateFrom: getStartDay(this.dateFrom),
              dateTo: getEndDay(this.dateTo),
              type: this.tabName,
            },
            {responseType: "blob"}
        );
        if (data?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          return this.$snotify['error']('Excel se nepodařilo vygenerovat');
        }

        const startDate = moment.utc(this.dateFrom).format('YYYY-MM-DD');
        const endDate = moment.utc(this.dateTo).format('YYYY-MM-DD');
        const fileName = `Zakázky_Export_${startDate}_${endDate}.xlsx`;

        this.$snotify['success']('Exportován do Excelu');
        saveAs(data, fileName);
      } catch (e) {
        console.error('exportExcel', e.message);
        this.$snotify['error'](e.message || 'Internal Server Error');
      }
    },
    async changeTab() {
      this.offset = 0;
      await this.getItems(true);
    },
  },
  watch: {
    dateFrom: {
      async handler() {
        await this.changeTab();
      }
    },
    dateTo: {
      async handler() {
        await this.changeTab();
      }
    },
    tabName: {
      async handler() {
        await this.changeTab();
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getConstants();
    await this.getItems(true);
    const table = this.$el.querySelector('.table-responsive');
    if (table) {
      table.addEventListener('scroll', this.handleScroll);
    }
    this.isLoading = false;
  },
  beforeDestroy() {
    if (typeof this.$el === 'object' && this.$el !== null && 'querySelectorAll' in this.$el) {
      this.$el.querySelector('.table-responsive').removeEventListener('scroll', this.handleScroll);
    }
  },
}
</script>
<style>
  .exportExcel {
    position: relative;
    top: -70px;
    left: calc(100% - 145px);
  }
</style>