<template>
  <div class="pl-2 pr-2 h-max-content">
    <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
      <div class="text-left button-line d-flex justify-content-between pl-1 pr-2">
      </div>
    </b-overlay>
  </div>
</template>
<script>

import commonMixin from "@/mixins/common.mixin.ts";

export default {
    name: 'Grafy',
    props: {

    },
  components: {},
  mixins: [commonMixin],
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async getItems() {
      // try {
      //
      // } catch (e) {
      //   console.error('getItems', e.response?.data);
      // } finally {
      //   this.isLoading = false;
      // }
    },
    async getConstants() {
      // try {
      //   const dataConst = await this.getConstantsFromServer(['DPH']) || {};
      //   this.lists.dph = dataConst.DPH;
      // } catch (e) {
      //   console.error('getConstants', e);
      // }
    },
  },
  async mounted() {
    this.isLoading = true;
    // await this.getConstants();
    // await this.getItems(true);
    this.isLoading = false;
  },
}
</script>
<style>

</style>