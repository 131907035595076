<template>
  <div v-if="isHaveAccess">
    <div class="text-left button-line pl-2">
      <tab-buttons :tabName="tabName" :tabs="TABS" class="pb-4 text-left" />
    </div>

    <div v-if="isPodepsaneSmlouvyTabShow">
      <podepsane-smlouvy-tab :tabName="tabName" />
    </div>
    <div v-else-if="isGrafyTabShow">
      <grafy-tab />
    </div>
  </div>
</template>


<script>

import PodepsaneSmlouvyTab from "@/views/financniAnalyza/PodepsaneSmlouvyTab.vue";
import GrafyTab from "@/views/financniAnalyza/GrafyTab.vue";
import TabButtons from "@/components/TabButtons.vue";
import {TABULKA_TYPE} from "@/constants/form.constants";

const TABS = [
  {tabName: TABULKA_TYPE.TABULKA_2, link: '/financni-analyza/tabulka-2', name: 'Firmy a veřejnoprávní subjekty', title: 'Firmy a veřejnoprávní subjekty'},
  {tabName: TABULKA_TYPE.TABULKA_1, link: '/financni-analyza/tabulka-1', name: 'Bytové a rodinné domy', title: 'Rodinné a bytové domy'},
  {tabName: 'grafy', link: '/financni-analyza/grafy', name: 'Grafy', disabled: true},
];

export default {
  components: {TabButtons, PodepsaneSmlouvyTab, GrafyTab},
  props: {
    tabName: {type: String, required: true},
  },
  computed: {
    isHaveAccess() {
      return this.$store.getters.isFinancniAnalyzaPageAllowed;
    },
    isPodepsaneSmlouvyTabShow() {
      return [TABULKA_TYPE.TABULKA_1, TABULKA_TYPE.TABULKA_2].includes(this.tabName);
    },
    isGrafyTabShow() {
      return this.tabName === 'grafy';
    },
  },
  data() {
    return {
      TABS,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

